import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../src/javascripts/i18n_stimulus'
import { serialize } from '../src/javascripts/serialize'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['table', 'form']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      queryParams: (params) => this.mergeFormParams(params, this.formParams),
      exportOptions: {
        fileName: I18n.t('other', { scope: 'activerecord.models.advantage' }),
      },
      columns: [
        {
          field: 'title',
          sortable: true,
          formatter: this.titleFormatter,
          title: I18n.t('title', { scope: 'activerecord.attributes.advantage' }),
        },
        {
          field: 'description',
          sortable: true,
          formatter: this.descriptionFormatter,
          title: I18n.t('description', { scope: 'activerecord.attributes.advantage' }),
        },
        {
          field: 'advantage_category.name',
          title: I18n.t('category', { scope: 'activerecord.attributes.advantage' }),
        },
        {
          field: 'location_name',
          sortable: true,
          formatter: this.locationFormatter,
          title: I18n.t('location_name', { scope: 'activerecord.attributes.advantage' }),
        },
        {
          field: 'location_city',
          sortable: true,
          title: I18n.t('location_city', { scope: 'activerecord.attributes.advantage' }),
        },
        {
          field: 'date_start',
          sortable: true,
          formatter: this.dateFormatter,
          title: I18n.t('available_date', { scope: 'activerecord.attributes.advantage' }),
        },
        {
          field: 'advantage_voidings',
          formatter: this.voidingsFormatter,
          title: I18n.t('voidings', { scope: 'activerecord.attributes.advantage' }),
        }
      ],
    }
  }

  titleFormatter(value, row) {
    return DOMPurify.sanitize(
      `<a href='${location.href}/${row.uid}' class='text-secondary'>${row.title}</a>`
    )
  }

  descriptionFormatter(value, row) {
    if (!value) return
    const description = value.length > 30 ? value.substring(0, 27) + '...' : value
    return description
  }

  locationFormatter(value, row) {
    if (value) return value
    else if (row.accommodation_provider)
      return DOMPurify.sanitize(
        `<a href='/accommodation_providers/${row.accommodation_provider.uid}' class='text-secondary'>${row.accommodation_provider.name}</a>`
      )
  }

  dateFormatter(value, row) {
    if (row.date_start) {
      return `${I18n.l('date.formats.long', row.date_start)} - ${I18n.l(
        'date.formats.long',
        row.date_end
      )}`
    }
  }

  voidingsFormatter(value, row) {
    if (value) return value.length
    return 0;
  }

  mergeFormParams(params, formParams) {
    const filter = document.querySelector('[name=created_by]:checked')
    if (!filter) return params

    params.created_by = filter.value
    return params
  }

  filterAdvantages() {
    const pageLimit = '&limit=' + document.querySelector('.page-size').innerText
    const searchInput = '&search=' + document.querySelector('.search-input').value
    const params = serialize(this.formTarget) + pageLimit + searchInput
    $(this.tableTarget).bootstrapTable('showLoading')
    fetch('/advantages.json?' + params)
      .then((response) => response.json())
      .then((data) => {
        $(this.tableTarget).bootstrapTable('load', data)
        $(this.tableTarget).bootstrapTable('hideLoading')
      })
  }
}
