import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    this.initVoidingsSelector()
  }

  initVoidingsSelector() {
    const selectorElement = document.querySelector("#advantage_voidings_selector");
    const displayElement = document.querySelector("#advantage_voidings_selected");
    if (selectorElement && displayElement) {
      displayElement.textContent = selectorElement.value;
      selectorElement.addEventListener('change', (event) => {
        const value = event.target.value;
        displayElement.textContent = value;
      })
    }
  }
}
