import { Controller } from 'stimulus'
import { initI18n } from '../../src/javascripts/i18n_stimulus'
import { checkImageFileSize } from '../../src/javascripts/check_image_size'

export default class extends Controller {
  connect() {
    initI18n()
    checkImageFileSize('#advantage_filereference', I18n.t('image_too_big', { scope: 'activerecord.alerts.advantage' }))
  }
}
