export function checkImageFileSize(documentSelector, alertError) {
    const imageField = document.querySelector(documentSelector);
    imageField.addEventListener('change', (event) => {
      const target = event.target;
      const targetFiles = target.files;
      if (targetFiles && targetFiles[0]) {
        const maxAllowedSize = 1 * 1024 * 1024;
        if (targetFiles[0].size > maxAllowedSize) {
          alert(alertError);
          target.value = "";
        }
      }
    })
  }
  